<template>
  <div>
    <el-card>
      <div class="topHeader clearfix">
        <div>
          <span class="userName"
            >当前登录: <span>{{ userName }}</span></span
          >
        </div>
        <el-header :class="{ head_read: true }">
          <el-form label-width="160px" ref="readFormRef" :model="readForm" class="readForm">
            <el-form-item prop="rfid_no" label="RFID：" size="samll" :rules="[{ required: true }]">
              <el-input
                v-model="readForm.murf_no"
                ref="cardRef"
                :disabled="isDisabled"
                placeholder="请刷卡"
                show-word-limit
                class="readInput"
                @input="cardChange"
              >
                <template slot="append">
                  <el-link type="primary" @click="emptyChange" class="empty vg_pointer">清空</el-link>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="模具编号：" size="samll" prop="smpl_no">
              <el-input :disabled="!isStpr" v-model="readForm.mdnc_no" class="readInput"></el-input>
            </el-form-item>
            <el-form-item label="委托单号：" size="samll" prop="requ_no">
              <el-input :disabled="true" v-model="readForm.requ_no" class="readInput"></el-input>
            </el-form-item>
            <el-form-item label="工序：" size="samll" prop="defa_proc">
              <el-select v-model="readForm.murf_proc_name" @change="seChange" placeholder="请选择" popper-class="selectRead">
                <el-option v-for="item in procList" :key="item.key" :label="item.label" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-header>
      </div>
      <el-main style="height: 100%">
        <dynamic-form
          v-bind="$attrs"
          :split="split"
          :form-list="formList"
          :need-btn="false"
          :name="readForm.murf_proc_name"
          ref="formList"
          :lableWidth="`140px`"
          class="dynamicForm"
          :selectOptionStyle="`font-size:24px`"
        >
          <div :slot="`header-btn-group-${readForm.murf_proc_name}`" v-if="!['检验完成', '模具完成'].includes(readForm.murf_proc_name)">
            <el-button @click="addChildFromRow" type="primary" class="openDetermine2">新增</el-button>
            <el-button @click="delLastRow" type="danger" class="openDetermine2">删除</el-button>
          </div>
        </dynamic-form>
        <dynamic-form
          v-if="elapsedTimeFromShow"
          v-bind="$attrs"
          :split="split"
          :form-list="elapsedTimeFrom"
          :need-btn="false"
          :name="readForm.murf_proc_name"
          ref="elapsedTimeFrom"
          :lableWidth="`140px`"
          :selectOptionStyle="`font-size:24px`"
          class="dynamicForm"
        ></dynamic-form>
        <dynamic-form
          v-if="costFormShow"
          v-bind="$attrs"
          :split="split"
          :form-list="costForm"
          :need-btn="false"
          :name="readForm.murf_proc_name"
          ref="costForm"
          :lableWidth="`140px`"
          class="dynamicForm"
          :selectOptionStyle="`font-size:24px`"
        ></dynamic-form>
        <dynamic-form
          v-bind="$attrs"
          :form-list="remarkForm"
          :split="24"
          :need-btn="false"
          :name="readForm.murf_proc_name"
          ref="remarkForm"
          :lableWidth="`140px`"
          class="dynamicForm"
          :selectOptionStyle="`font-size:24px`"
        ></dynamic-form>
        <el-button @click="submit" class="openDetermine" type="success" style="font-size: 24px; margin-top: 10px">提交</el-button>
      </el-main>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post, postNoCatch } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import { procAPI } from '@api/modules/proc';
import OpEdi from '@/views/OperateManagement/ReadManage/TabChild/OpEdi.vue';
import Stpr from '@/views/OperateManagement/ReadManage/TabChild/Stpr.vue';
import Mase from '@/views/OperateManagement/ReadManage/TabChild/Mase.vue';
import Inspec from '@/views/OperateManagement/ReadManage/TabChild/Inspec.vue';
import Manual from '@/views/OperateManagement/ReadManage/TabChild/Manual.vue';
import Embr from '@/views/OperateManagement/ReadManage/TabChild/Embr.vue';
import Lase from '@/views/OperateManagement/ReadManage/TabChild/Lase.vue';
import Comp from '@/views/OperateManagement/ReadManage/TabChild/Comp.vue';
import Stam from '@/views/OperateManagement/ReadManage/TabChild/Stam.vue';
import Emcd from '@/views/OperateManagement/ReadManage/TabChild/Emcd.vue';
import { mupcAPI } from '@api/modules/mupc';
import { cloneDeep } from 'lodash';
import { muopAPI } from '@api/modules/muop';
import { dateToTimeStamp, getDate, getDateNoTime, getElapsedTime } from '@assets/js/dateUtils';
import DynamicForm from '@/components/DynamicForm.vue';

export default {
  name: 'readCard',
  components: {
    DynamicForm,
    OpEdi,
    Stpr,
    Mase,
    Inspec,
    Manual,
    Embr,
    Lase,
    Comp,
    Stam,
    Emcd
  },
  watch: {
    'readForm.defa_proc'(newValue, oldValue) {
      this.isStpr = newValue === 'stpr';
    }
  },
  data() {
    return {
      costFormShow: false,
      elapsedTimeFromShow: false,
      split: 8,
      remarkForm: [{ label: '备注', prop: 'proceRemark', itemType: 'input', model: '', index: 0, rows: 5, type: 'textarea' }],
      timeForm: [
        { label: '开始时间', prop: 'startTime', itemType: 'date', model: null, index: 0, formatTime: 'yyyy-MM-dd HH:mm:ss' },
        { label: '结束时间', prop: 'endTime', itemType: 'date', model: null, index: 0, formatTime: 'yyyy-MM-dd HH:mm:ss' }
      ],
      defaultFrom: [
        {
          label: '选择模板',
          prop: 'proceTemplate',
          itemType: 'select',
          options: [{ label: '板片A', value: '1' }],
          model: '1',
          index: 0
        }
      ],
      costForm: [{ label: '委外费用', prop: 'proceMoney', itemType: 'input', model: '', index: 0, inputAppend: '元' }],
      machineFrom: [
        {
          label: '选择机器',
          prop: 'proceMachine',
          itemType: 'select',
          options: [{ label: '板片A', value: '1' }],
          model: '1',
          index: 0
        }
      ],
      placeTimeFrom: [{ label: '到位时间', prop: 'placeTime', itemType: 'date', model: null, index: 0 }],
      elapsedTimeFrom: [{ label: '总耗时', prop: 'sumTime', itemType: 'input', model: '', index: 0, disabled: true }],
      formList: [],
      isStpr: false,
      readForm: {},
      procList: [
        { key: '设计', label: '设计' },
        { key: '编程', label: '编程' },
        { key: '钢材到位', label: '钢材到位' },
        { key: '模架到位', label: '模架到位' },
        { key: 'CNC', label: 'CNC' },
        { key: 'EDM', label: 'EDM' },
        { key: '线切割', label: '线切割' },
        { key: '车床', label: '车床' },
        { key: '省模', label: '省模' },
        { key: '装配', label: '装配' },
        { key: '表面处理', label: '表面处理' },
        { key: '检验完成', label: '检验完成' },
        { key: '模具完成', label: '模具完成' }
      ],
      procForm: {},
      //员工拥有的所有工序
      proc_listc: [],
      eProcList: [],
      prop: {
        user_id: '',
        stff_id: '',
        stff_name: '',
        open_status: '',
        rfid_id: '',
        user_rfid_no: '',
        mode: null,
        smpl_id: null
      },
      prop2: {},
      stprProp: {},
      isDisabled: false,
      userName: '',
      SmplId: null,
      childForm: {}
    };
  },
  mounted() {
    this.getProcForm();
    this.getCookie();
    this.$refs.cardRef.focus();
    if (this.$cookies.get('userMode').type == 1) {
      this.readForm.defa_proc = this.$route.query.proc;
    }
    this.$EventBus.$on('openRead', () => {
      setTimeout(() => {
        this.focus();
      }, 500);
    });
    this.userName = this.$cookies.get('userInfo').stff_name;
  },
  methods: {
    delLastRow() {
      let map = this.formList.map(({ index }) => index);
      let max = Math.max(...map);
      if (max === 0) return;
      this.formList = this.formList.filter(x => x.index !== max);
    },
    addChildFromRow() {
      console.log(111111, this.readForm.murf_proc_name);
      let from;
      switch (this.readForm.murf_proc_name) {
        case '设计':
          from = cloneDeep(this.defaultFrom.concat(this.timeForm));
          break;
        case '编程':
          from = cloneDeep(this.defaultFrom.concat(this.timeForm));
          break;
        case '钢材到位':
          from = cloneDeep(this.defaultFrom.concat(this.placeTimeFrom));
          break;
        case '模架到位':
          from = cloneDeep(this.defaultFrom.concat(this.placeTimeFrom));
          break;
        case 'CNC':
          from = cloneDeep(this.defaultFrom.concat(this.machineFrom).concat(this.timeForm));
          break;
        case 'EDM':
          from = cloneDeep(this.defaultFrom.concat(this.machineFrom).concat(this.timeForm));
          break;
        case '线切割':
          from = cloneDeep(this.timeForm);
          break;
        case '车床':
          from = cloneDeep(this.timeForm);
          break;
        case '省模':
          from = cloneDeep(this.timeForm);
          break;
        case '装配':
          from = cloneDeep(this.timeForm);
          break;
        case '表面处理':
          from = cloneDeep(this.timeForm);
          break;
      }
      from.forEach(item => (item.index = this.formList[this.formList.length - 1].index + 1));
      this.formList = this.formList.concat(from);
    },
    submit() {
      let specialFormModel = this.$refs.formList.getSpecialFormModel();
      if (this.elapsedTimeFromShow) {
        Object.assign(specialFormModel, this.$refs.elapsedTimeFrom.getFormModel());
      }
      if (this.costFormShow) {
        Object.assign(specialFormModel, this.$refs.costForm.getFormModel());
      }
      Object.assign(specialFormModel, this.$refs.remarkForm.getFormModel());
      let { rfid_no, mmpl_id, murf_id, murf_proc_name } = this.readForm;
      let { user_id, stff_id, stff_name } = this.$cookies.get('userInfo');
      specialFormModel.rfid_no = rfid_no;
      specialFormModel.mmpl_id = mmpl_id;
      specialFormModel.murf_id = murf_id;
      specialFormModel.murf_proc_name = murf_proc_name;
      specialFormModel.user_id = user_id;
      specialFormModel.stff_id = stff_id;
      specialFormModel.stff_name = stff_name;
      postNoCatch(muopAPI.editMurfProc, specialFormModel).then(({ data, code, msg }) => {
        this.$message.success('提交成功!');
        this.seChange(this.readForm.murf_proc_name);
      });
    },
    seChange(val) {
      this.readForm.murf_proc_name = val;
      if (this.readForm.murf_no !== '') {
        switch (val) {
          case '设计':
            this.split = 8;
            this.formList = cloneDeep(this.defaultFrom.concat(this.timeForm));
            this.costFormShow = false;
            this.elapsedTimeFromShow = true;
            break;
          case '编程':
            this.split = 8;
            this.formList = cloneDeep(this.defaultFrom.concat(this.timeForm));
            this.costFormShow = false;
            this.elapsedTimeFromShow = true;
            break;
          case '钢材到位':
            this.split = 12;
            this.formList = cloneDeep(this.defaultFrom.concat(this.placeTimeFrom));
            this.elapsedTimeFromShow = false;
            this.costFormShow = false;
            break;
          case '模架到位':
            this.split = 12;
            this.formList = cloneDeep(this.defaultFrom.concat(this.placeTimeFrom));
            this.elapsedTimeFromShow = false;
            this.costFormShow = false;
            break;
          case 'CNC':
            this.split = 6;
            this.formList = cloneDeep(this.defaultFrom.concat(this.machineFrom).concat(this.timeForm));
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case 'EDM':
            this.split = 6;
            this.formList = cloneDeep(this.defaultFrom.concat(this.machineFrom).concat(this.timeForm));
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case '线切割':
            this.split = 12;
            this.formList = cloneDeep(this.timeForm);
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case '车床':
            this.split = 12;
            this.formList = cloneDeep(this.timeForm);
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case '省模':
            this.split = 12;
            this.formList = cloneDeep(this.timeForm);
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case '装配':
            this.split = 12;
            this.formList = cloneDeep(this.timeForm);
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case '表面处理':
            this.split = 12;
            this.formList = cloneDeep(this.timeForm);
            this.costFormShow = true;
            this.elapsedTimeFromShow = true;
            break;
          case '检验完成':
            this.split = 12;
            this.costFormShow = false;
            this.elapsedTimeFromShow = false;
            break;
          case '模具完成':
            this.split = 24;
            this.costFormShow = false;
            this.elapsedTimeFromShow = false;
            break;
        }
        this.getData(this.readForm.murf_proc_name);
      } else {
        this.$nextTick(() => {
          this.$refs.cardRef.focus();
        });
      }
    },
    getProcForm() {
      getNoCatch(mupcAPI.getUserMupc).then(({ data }) => {
        this.procForm = data.form;
        let processNameList = this.procForm.mupc_names.split(',');
        this.procList = [];
        processNameList.forEach(item => this.procList.push({ key: item, label: item }));
        this.readForm.murf_proc_name = this.procForm.mupc_defa;
        this.seChange(this.procForm.mupc_defa);
      });
    },
    getCookie() {
      let userinfo = this.$cookies.get('userInfo');
      let mode = this.$cookies.get('userMode');
      this.mode = mode.type;
      this.prop.user_id = userinfo.user_id;
      this.prop.stff_id = userinfo.stff_id;
      this.prop.stff_name = userinfo.stff_name;
      this.prop.user_rfid_no = userinfo.rfid_no;
    },
    cardChange(val) {
      let trimValue = val.trim();
      if (trimValue.length === 10) {
        this.readForm.murf_no = trimValue;
        this.getData();
        this.isDisabled = true;
      }
    },
    getData() {
      if (!this.readForm.murf_no) return;
      let processName = this.readForm.murf_proc_name;
      getNoCatch(mupcAPI.readMurfCard, this.readForm).then(({ data }) => {
        this.readForm = data.murf;
        this.readForm.murf_proc_name = processName;
        this.procList = [];
        this.readForm.proc_order?.split(',').forEach(item => this.procList.push({ key: item, label: item }));
        let formList = cloneDeep(this.formList);
        let map = formList.map(({ prop }) => prop);
        let handledArr = [];
        data.proc.forEach((item, index) => {
          let deepForm = cloneDeep(formList);
          map.forEach((subItem, subIndex) => {
            deepForm[subIndex].model = item[subItem];
            deepForm[subIndex].index = index;
            deepForm[subIndex].proceId = item.proceId;
          });
          handledArr = handledArr.concat(deepForm);
        });
        this.formList = handledArr;
        if (this.elapsedTimeFromShow) this.elapsedTimeFrom[0].model = getElapsedTime(data?.sumTime ?? '');
        if (this.costFormShow) this.costForm[0].model = data?.proceMoney ?? '';
        this.remarkForm[0].model = data?.proceRemark ?? '';
      });
    },
    emptyChange() {
      let first = this.readForm.murf_proc_name;
      this.readForm = { murf_proc_name: first };
      this.isDisabled = false;
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
      this.seChange(first);
    },

    //
    empty() {
      this.readForm.rfid_no = '';
      this.readForm.smpl_no = '';
      this.readForm.requ_no = '';
      this.prop.open_status = '';
      this.prop.rfid_id = '';
      this.isDisabled = false;
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
    },
    outSuccess() {
      this.getData(this.readForm.defa_proc);
    },
    returnHome() {
      this.readForm.rfid_no = '';
      this.readForm.smpl_no = '';
      this.readForm.requ_no = '';
      this.prop.open_status = '';
      this.prop.rfid_id = '';
      this.isDisabled = false;
      this.emptyChange();
      this.jump('dashboard');
    },
    focus() {
      if (!this.$refs.cardRef) {
      } else {
        setTimeout(() => {
          this.$refs.cardRef.focus();
        }, 100);
      }
    },
    exitHome() {
      this.$cookies.remove('userInfo');
      this.$cookies.remove('push');
      this.$cookies.remove('userMode');
      this.jump('login');
      this.$cookies.set('loginType', false, 60 * 60 * 24 * 7);
    }
  }
};
</script>

<style lang="scss">
.retHome {
  float: left;
  height: 85px;
  font-size: 20px;
  text-align: center;
}

.readForm .el-form-item__label {
  font-size: $font-max;
}

.readInput {
  margin-bottom: 10px !important;
  font-size: $font-max;
  height: 50px;
}

.readInput input {
  height: 50px;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

.topHeader {
  display: flex;
  border-bottom: 2px dotted #cccccc;
}

.head_read {
  width: 75%;
  float: left;
  padding-bottom: 20px;
  height: 250px !important;
}

.readForm {
  width: 75%;
  margin: 0 auto;
  min-width: 500px;
}

.empty {
  font-size: $font-max;
}

.readForm .el-input__inner {
  font-size: $font-max;
  height: 50px;
}

.selectRead li {
  font-size: $font-max;
  height: 60px;
  line-height: 60px;
}

.userName {
  font-size: 20px;

  span {
    font-weight: 700;
    font-size: 24px;
  }
}
.openDetermine {
  width: 280px;
  height: 80px;
  font-size: 24px;
}
.openDetermine2 {
  width: 120px;
  height: 50px;
  font-size: 24px;
  margin-bottom: 10px;
}
.dynamicForm .el-form-item__label {
  font-size: 24px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
}
.dynamicForm .el-input__inner {
  font-size: 24px;
  height: 50px;
}
.dynamicForm .el-textarea__inner {
  font-size: 24px;
}
.dynamicForm .el-input__icon {
  font-size: 24px;
  display: flex;
  align-items: center;
}
.dynamicForm .el-select-dropdown__item {
  font-size: 24px;
}
</style>
